<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001 mt-200 mb-50">
						<img :src="require('@/assets/image/logo_60.png')" alt="마피아">
						<div class="intro_top character2">
							<div class="text_guide pt-20">
								<h4>MAFIA가 되신걸 환영합니다.</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>MAFIA는 모든 예술 분야를 아우르는<br/> 문화 마피아입니다.</p>
								<p>NFT 캐릭터 카드를 구입해 <br/> 카르텔(조직)을 생성하고 가입하여 <br/> 다양한 소통을 나눌 수 있습니다.</p>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

    <div class="intro_btn_wrap">
      <div class="btn_area">
        <button
            @click="toLogin"
            class="btn_l btn_fill_blue"
        >확인</button>
      </div>
    </div>
	</div>
</template>

<script>
export default {
	name: 'mafia010'
	,data: function(){
		return {
			program: {
				name: '회원가입 완료'
				, not_header: true
				, not_footer: true
        , type: 'auth'
			}
		}
	}
	,methods: {
		toLogin: function(){
			document.location.href = '/'
			// this.$emit('to', { name: 'login'})
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>
<style>
/* 인트로 */
.text_guide h4 { display: inline-block; width: 100%; font-size: 2rem; font-weight: 500; color: #fff; text-align: center;}
.text_guide_desc p { line-height: 2.1rem; font-size: 1.1rem; color: var(--gray01); text-align: center;}
.text_guide_desc p + p { margin-top: 1rem; }

.bg_w .text_guide h4 { color: var(--dark-Gray02); font-weight: 700;}
.bg_w .text_guide_desc .text { color: var(--dark-Gray02);  }
.bg_w .text_guide_desc .point_text { color: var(--red); font-weight: 500; font-size: 1.6rem; line-height: 2.4rem; }



.btn_l {
  display: inline-block;
  height: 3.5rem;
  line-height: 1rem;
  min-width: 9rem;
  padding: 0 3rem;
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  box-sizing: border-box;
}

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }

.flex-column {
  justify-content: space-between;
}

</style>